.carousel2 {
  width: 900px;
  height: 500px;
  margin: auto;
}

@media (max-width: 900px) {
  .carousel2 {
    width: auto;
    height: auto;
  }
}
