.input2 {
  border: 0px solid #ced4da;
  border: 1px solid #000;
  border-right: none;
  background-color: #e6e6e6;
  border-radius: 0px;
}

.input2:focus {
  border: 0px solid #ced4da;
  border: 1px solid #000;
  border-right: none;
  background-color: #e6e6e6;
  border-radius: 0px;
  box-shadow: none;
}

.input2Button {
  border-radius: 0px;
  border-left: none;
}
