.nav-bg {
  background-color: #fcf7e4;
}

input[type="text"] {
  background-color: #3cbc8d;
  color: white;
}

.customInput {
  border: 0px solid #ced4da;
  border-bottom: 1px solid #000;
  background-color: #fcf7e4;
  border-radius: 0px;
}

.customInput:focus {
  border: 0px solid #ced4da;
  border-bottom: 1px solid #000;
  background-color: #fcf7e4;
  border-radius: 0px;
  box-shadow: none;
}

.customInputButton {
  border-bottom: 1px solid #000;
  color: #000;
  padding: 6px;
}
