.col {
  overflow: hidden;
}

.hoverE {
  object-fit: cover;
  transition: all 0.3s ease;
}

.hoverE:hover {
  transform: scale(1.2);
}
